.iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

iframe {
  width: 100%;
  height: 413px;
  border: none;
  /* Remove default iframe border */
}

.progress-bar {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 4px;
  /* Adjust as needed */
  background-color: #262626ff;
  /* Example color */
  /* Add any other styling for the progress bar */
}


/* App.css */

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #6b7280;
  /* Gray-500 */
  border-radius: 5px;
  border: 2px solid #f3f4f6;
  /* Gray-100 (background color) */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #4b5563;
  /* Gray-600 */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f3f4f6;
  /* Gray-100 */
  border-radius: 5px;
}